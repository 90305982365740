import { lazy, Suspense, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";

import Footer from "../components/Footer";
import Header from "../components/Header";

import routes from "./config";
import GlobalStyles from "../globalStyles";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

const Router = () => {



  return (
    <Suspense fallback={null}>
      <GlobalStyles />
      {/* <Header /> */}
      <Switch>
        {routes.map((routeItem) => {
          return (
            routeItem.public ? (
              <PublicRoute key={routeItem.component} restricted={routeItem.restricted} path={routeItem.path} exact={routeItem.exact} component={lazy(() => import(`../pages/${routeItem.component}`))}/>
            ) : (
              <PrivateRoute key={routeItem.component} restricted={routeItem.restricted} path={routeItem.path} exact={routeItem.exact} component={lazy(() => import(`../pages/${routeItem.component}`))}/>
            )
          );
        })}
      </Switch>
      {/* <Footer /> */}
    </Suspense>
  );
};

export default Router;
