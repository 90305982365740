import axios from 'axios'
import { API } from 'aws-amplify';
import {
  FETCH_ARTWORK_REQUEST,
  FETCH_ARTWORK_SUCCESS,
  FETCH_ARTWORK_FAILURE,
  POST_ARTWORK_REQUEST,
  POST_ARTWORK_SUCCESS,
  POST_ARTWORK_FAILURE,
  UPDATE_ARTWORK_REQUEST,
  UPDATE_ARTWORK_SUCCESS,
  UPDATE_ARTWORK_FAILURE,
  DELETE_ARTWORK_REQUEST,
  DELETE_ARTWORK_SUCCESS,
  DELETE_ARTWORK_FAILURE,
  USERID_ARTWORK_REQUEST,
  USERID_ARTWORK_SUCCESS,
  USERID_ARTWORK_FAILURE,
  CLEAR_STORE,
  SET_SELECTED_ART_WORK

} from './type'
import { ToastContainer ,toast } from 'react-toastify';


const apiName = 'backend-api';
const path = '/api/art-works';
const myInit = { // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  // queryStringParameters: {  // OPTIONAL
  //   name: 'param',
  // },
};

export const fetchArtWork=()=>{
    return(dispatch) =>{
        dispatch(fetchArtWorkRequest())
        API.get(apiName, path, myInit)
        .then(response => {

        // response.data is the users
        const artWork = response.data
        dispatch(fetchArtWorkSuccess(artWork))
      })
      .catch(error => {
        // error.message is the error message
        dispatch(fetchArtWorkFailure(error.response.data.message))
      })
    }
}

export const createArtWork = (data) => {
  myInit.body = data;
  return (dispatch) => {
    dispatch(postArtWorkRequest())
    API
    .post(apiName, path, myInit)
      .then(response => {
        // console.log(response)
        // response.data is the users
        const artWork = response.data;
        dispatch(postArtWorkSuccess(artWork))
        toast.success("Successful")
        
        dispatch(fetchArtWork())
        
      })
      .catch(error => {
        // error.message is the error message
        dispatch(postArtWorkFailure(error.response.data.message))
        {
          toast.error("Unsuccessful")
        }
      })
  }
}

export const deleteArtWork = (id) => {
  return (dispatch) => {
    dispatch(deleteArtWorkRequest())
    API
    .del(apiName, path+`/${id}`)
      .then(response => {

        // response.data is the users
        const artWork = response.data
        dispatch(deleteArtWorkSuccess(artWork))
      })
      .catch(error => {
        // error.message is the error message
        dispatch(deleteArtWorkFailure(error.response.data.message))
      })
  }
}

export const updateArtWork = (id, data) => {
  myInit.body = data;
  myInit.queryStringParameters = {id : id};
  return (dispatch) => {
    dispatch(updateArtWorkRequest())
    API
    .put(apiName, path, myInit)
      .then(response => {

        // response.data is the users
        const artWork = response.data
        dispatch(updateArtWorkSuccess(artWork))
        toast.success("Successful")
        
        dispatch(fetchArtWork())
        
      })
      .catch(error => {
        // error.message is the error message
        dispatch(updateArtWorkFailure(error.response.data.message))
        toast.error("Unsuccessful")
       })
  }
}

export const fetchArtistByUserid =(userid) => {
  return(dispatch) =>{
      dispatch(fetchArtWorkByUserIdRequest())
      API.get(apiName, path+`/${userid}`, myInit)
          .then(response => {

                // response.data is the users
      const artist = response.data
      dispatch(fetchArtWorkByUserIdSuccess(artist))
    })
    .catch(error => {
      // error.message is the error message
      dispatch(fetchArtWorkByUserIdFailure(error.response.data.message))
    })

  }

}

export const fetchArtWorkRequest = () => {
    return {
      type: FETCH_ARTWORK_REQUEST
    }
  }
  
  export const fetchArtWorkSuccess = artWork => {
    return {
      type: FETCH_ARTWORK_SUCCESS,
      payload: artWork,
      success: "succesful"

    }
  }
  
  export const fetchArtWorkFailure = error => {
    return {
      type: FETCH_ARTWORK_FAILURE,
      payload: error
    }
  }
  export const postArtWorkRequest = () => {
    return {
      type: POST_ARTWORK_REQUEST
    }
  }
  
  export const postArtWorkSuccess = artWork => {
    return {
      type: POST_ARTWORK_SUCCESS,
      payload: artWork,
      success: "succesful"
    }
  }
  
  export const postArtWorkFailure = error => {
    return {
      type: POST_ARTWORK_FAILURE,
      payload: error
    }
  }
  
  //DELETE REQUEST
  
  export const deleteArtWorkRequest = () => {
    return {
      type: DELETE_ARTWORK_REQUEST
    }
  }
  
  export const deleteArtWorkSuccess = artWork => {
    return {
      type: DELETE_ARTWORK_SUCCESS,
      payload: artWork,
      success: "succesfully deleted"
    }
  }
  
  export const deleteArtWorkFailure = error => {
    return {
      type: DELETE_ARTWORK_FAILURE,
      payload: error
    }
  }
  
  //update rquest
  
  export const updateArtWorkRequest = () => {
    return {
      type: UPDATE_ARTWORK_REQUEST
    }
  }
  
  export const updateArtWorkSuccess = artWork => {
    return {
      type: UPDATE_ARTWORK_SUCCESS,
      payload: artWork,
      success: "succesfully UPDATED"
    }
  }
  
  export const updateArtWorkFailure = error => {
    return {
      type: UPDATE_ARTWORK_FAILURE,
      payload: error
    }
  }
  export const fetchArtWorkByUserIdRequest = () => {
    return {
      type: USERID_ARTWORK_REQUEST
    }
  }

  export const fetchArtWorkByUserIdSuccess = artist => {
    return {
      type: USERID_ARTWORK_SUCCESS,
      payload: artist
    }
  }
  
  export const fetchArtWorkByUserIdFailure = error => {
    return {
      type: USERID_ARTWORK_FAILURE,
      payload: error
    }
  }
  
  export const clearArtWork = () =>{
    return{
      type : CLEAR_STORE
    }
  }

  export const setSelectedArtWork = (selectedArtWork) =>{
    return{
      type : SET_SELECTED_ART_WORK,
      payload: selectedArtWork
    }
    
  }
