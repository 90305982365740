import {
  FETCH_PUBLICARTIST_REQUEST,
  FETCH_PUBLICARTIST_SUCCESS,
  FETCH_PUBLICARTIST_FAILURE,
  FETCH_PUBLICARTWORK_REQUEST,
  FETCH_PUBLICARTWORK_SUCCESS,
  FETCH_PUBLICARTWORK_FAILURE
 
} from './type';

const initialState={
  loading: false,
  publicArtist : undefined,
  error  : '',
  success: ''
}

const reducer = (state = initialState , action)=>{
  switch(action.type){
    case FETCH_PUBLICARTIST_REQUEST :
      return{
        ...state,
        loading: true
      }
    case FETCH_PUBLICARTIST_SUCCESS :
    return{
      ...state,
      loading: false,
      publicArtist: action.payload,
      error: '',
      success: action.success
    }
    case FETCH_PUBLICARTIST_FAILURE :
      return{
        ...state,
        loading: false,
        publicArtist: undefined,
        error: action.payload
    }
    case FETCH_PUBLICARTWORK_REQUEST :
      return{
        ...state,
        loading: true
      }
    case FETCH_PUBLICARTWORK_SUCCESS :
      return{
        ...state,
        loading: false,
        artWork: action.payload,
        error: '',
        success: action.success
      }
    case FETCH_PUBLICARTWORK_FAILURE :
      return{
        ...state,
        loading: false,
        artWork: undefined,
        error: action.payload
    }
    default: return state
  }
}
export default reducer;