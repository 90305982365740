/**
 * Global Config
 */

const config = {
  cognito: {
    USER_POOL_ID: "ap-south-1_6Tz4OLn4d",
    APP_CLIENT_ID: "6n3t3poh0co772eaahgvopcfn",
  },
  api: {
    NAME: "KALA_CUBE_LAMBDA_SERVER", 
    endpoint: "https://1234567890-abcdefgh.amazonaws.com"
  }
}

// Domains
config.domains = {}

/**
 * API Domain
 * Add the domain from your serverless express.js back-end here.
 * This will enable your front-end to communicate with your back-end.
 * (e.g. 'https://api.mydomain.com' or 'https://091jafsl10.execute-api.us-east-1.amazonaws.com')
 */
// config.domains.api = 'https://7l9tp674ib.execute-api.us-east-1.amazonaws.com'

export default config