import { useState, Fragment, lazy } from "react";
import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";

import * as S from "./styles";
import { useHistory } from "react-router-dom";
import { useAppContext } from "../../common/ContextLib";
import { Auth } from "aws-amplify";
import store from "../../Redux/store";
import { clearArtist, clearArtStyle, clearArtWork, clearUser } from "../../Redux";


const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Button = lazy(() => import("../../common/Button"));

const Header = ({ t }) => {
  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);
  const { isAuthenticated, userHasAuthenticated } = useAppContext();

  const history = useHistory();

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const navTo = (link) => {

    // if (link.includes('submit-art-work')) {
    //   alert('Submit Art Work -- Coming Soon');
    // } else {
      history.push(link);
    // }
  }

  const MenuItem = () => {
    const scrollTo = (id) => {
      const element = document.getElementById(id);
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };

  const handleLogout = async () => {
    console.log('logout');
    await Auth.signOut();
    userHasAuthenticated(false);

    store.dispatch(clearArtist());
    store.dispatch(clearUser());
    store.dispatch(clearArtStyle());
    store.dispatch(clearArtWork());
    
  }
    return (
      <Fragment>
        <S.CustomNavLinkSmall onClick={() => navTo("/")}>
          <S.Span>Home</S.Span>
        </S.CustomNavLinkSmall>

        {isAuthenticated && (
          <>
            <S.CustomNavLinkSmall onClick={() => navTo("/profile")}>
              <S.Span>Profile</S.Span>
            </S.CustomNavLinkSmall>
            <S.CustomNavLinkSmall onClick={() => navTo("/submit-art-work")}>
              <S.Span> Submit Your Art Work</S.Span>
            </S.CustomNavLinkSmall>
          </>
        )}
        {/* <S.CustomNavLinkSmall onClick={() => scrollTo("mission")}>
          <S.Span>{t("Mission")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <S.Span>{t("Product")}</S.Span>
        </S.CustomNavLinkSmall> */}
        {!isAuthenticated ? (<S.CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={() => navTo("/registration")}
        >
          <S.Span>
            <Button>Login/Register</Button>
          </S.Span>
        </S.CustomNavLinkSmall>) : (<S.CustomNavLinkSmall
          style={{ width: "180px" }}
          onClick={handleLogout}
        >
          <S.Span>
            <Button>Logout</Button>
          </S.Span>
        </S.CustomNavLinkSmall>)}
      </Fragment>
    );
  };

  return (
    <S.Header>
      <S.Container>
        <Row type="flex" justify="space-between" gutter={20}>
          <S.LogoContainer to="/" aria-label="homepage">
            <SvgIcon src="Logo1.png" height="90px" />
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
