import {
    FETCH_USER_REQUEST,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,
    POST_USER_REQUEST,
    POST_USER_SUCCESS,
    POST_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    CLEAR_STORE
  } from './type'
  
  const initialState = {
    loading: false,
    user: undefined,
    error: '',
    success: ''
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_USER_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_USER_SUCCESS:
        return {
          loading: false,
          user: action.payload,
          error: '',
          success: action.success
        }
      case FETCH_USER_FAILURE:
        return {
          loading: false,
          user: undefined,
          error: action.payload
        }
        case POST_USER_REQUEST:
        return {
          ...state,
          loading: true
        }
      case POST_USER_SUCCESS:
        return {
          loading: false,
          user: action.payload,
          error: '',
          success: action.success
        }
      case POST_USER_FAILURE:
        return {
          loading: false,
          user: undefined,
          error: action.payload
        }
      case DELETE_USER_REQUEST:
        return {
          ...state,
          loading: true
        }
      case DELETE_USER_SUCCESS:
        return {
          loading: false,
          user: action.payload,
          error: '',
          success: action.success
        }
      case DELETE_USER_FAILURE:
        return {
          loading: false,
          user: undefined,
          error: action.payload
        }
        case UPDATE_USER_REQUEST:
        return {
          ...state,
          loading: true
        }
      case UPDATE_USER_SUCCESS:
        return {
          loading: false,
          user: action.payload,
          error: '',
          success: action.success
        }
      case UPDATE_USER_FAILURE:
        return {
          loading: false,
          user: undefined,
          error: action.payload
        }
      case CLEAR_STORE:               
        return {
        loading: false,
        artist: undefined,
        error: ''       
      } 
      default: return state
    }
  }
  
  export default reducer
  