
import { combineReducers } from "redux";
import artStyleReducer from './ArtStyle/reducer'
import artistReducer from './Artist/reducer'
import artWorkReducer from './ArtWork/reducer'
import userReducer from './User/reducer'
import popularArtistReducer from './PopularArtist/reducer';
import publicProfileReducer from './PublicProfile/reducer';


const rootReducer = combineReducers({ 
    artStyle: artStyleReducer,
    artist : artistReducer,
    artWork : artWorkReducer,
    user: userReducer,
    popArtist : popularArtistReducer,
    publicArtist: publicProfileReducer
});
export default rootReducer;