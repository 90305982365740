import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import './styles.css';

const PopUpTerm = ({ title, content, isModalVisible, setIsModalVisible }) => {

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };    

  return (
    <Modal title={title} visible={isModalVisible} onOk={handleOk}  onCancel={handleCancel} footer={true} width={1000}>

      <div className="container">
        <div className="row">
          {/* < div className="col-md-12 text-center">
                        <h2>{title}</h2>
                    </div> */}
          < div className="col-md-12 content text-​justify" >
            <p>{content}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default PopUpTerm;
