export const FETCH_ARTIST_ALL_ARTWORK_REQUEST = 'FETCH_ARTIST_ALL_ARTWORK_REQUEST'
export const FETCH_ARTIST_ALL_ARTWORK_SUCCESS = 'FETCH_ARTIST_ALL_ARTWORK_SUCCESS'
export const FETCH_ARTIST_ALL_ARTWORK_FAILURE = 'FETCH_ARTIST_ALL_ARTWORK_FAILURE'

export const FETCH_ARTWORK_REQUEST = 'FETCH_ARTWORK_REQUEST'
export const FETCH_ARTWORK_SUCCESS = 'FETCH_ARTWORK_SUCCESS'
export const FETCH_ARTWORK_FAILURE = 'FETCH_ARTWORK_FAILURE'

export const POST_ARTWORK_SUCCESS = 'POST_ARTWORK_SUCCESS'
export const POST_ARTWORK_REQUEST = 'POST_ARTWORK_REQUEST'
export const POST_ARTWORK_FAILURE = 'POST_ARTWORK_FAILURE'

export const DELETE_ARTWORK_REQUEST = 'DELETE_ARTWORK_REQUEST'
export const DELETE_ARTWORK_SUCCESS = 'DELETE_ARTWORK_SUCCESS'
export const DELETE_ARTWORK_FAILURE = 'DELETE_ARTWORK_FAILURE'

export const UPDATE_ARTWORK_REQUEST = 'UPDATE_ARTWORK_REQUEST'
export const UPDATE_ARTWORK_SUCCESS = 'UPDATE_ARTWORK_SUCCESS'
export const UPDATE_ARTWORK_FAILURE = 'UPDATE_ARTWORK_FAILURE'


export const USERID_ARTWORK_REQUEST = 'USERID_ARTWORK_REQUEST'
export const USERID_ARTWORK_SUCCESS = 'USERID_ARTWORK_SUCCESS'
export const USERID_ARTWORK_FAILURE = 'USERID_ARTWORK_FAILURE'

export const SET_SELECTED_ART_WORK = 'SET_SELECTED_ART_WORK';

export const CLEAR_STORE = 'CLEAR_STORE'
