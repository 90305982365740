import {
  FETCH_ARTSTYLE_REQUEST,
  FETCH_ARTSTYLE_SUCCESS,
  FETCH_ARTSTYLE_FAILURE,
  POST_ARTSTYLE_REQUEST,
  POST_ARTSTYLE_SUCCESS,
  POST_ARTSTYLE_FAILURE,
  DELETE_ARTSTYLE_REQUEST,
  DELETE_ARTSTYLE_SUCCESS,
  DELETE_ARTSTYLE_FAILURE,
  UPDATE_ARTSTYLE_REQUEST,
  UPDATE_ARTSTYLE_SUCCESS,
  UPDATE_ARTSTYLE_FAILURE,
  CLEAR_STORE
} from './type'

const initialState = {
  loading: false,
  artStyle: [],
  error: ''
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ARTSTYLE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case FETCH_ARTSTYLE_SUCCESS:
      return {
        loading: false,
        artStyle: action.payload,
        error: ''
      }
    case FETCH_ARTSTYLE_FAILURE:
      return {
        loading: false,
        artStyle: [],
        error: action.payload
      }
      case POST_ARTSTYLE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case POST_ARTSTYLE_SUCCESS:
        return {
          loading: false,
          user: action.payload,
          error: '',
          success: action.success
        }
      case POST_ARTSTYLE_FAILURE:
        return {
          loading: false,
          user: [],
          error: action.payload
        }
      case DELETE_ARTSTYLE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case DELETE_ARTSTYLE_SUCCESS:
        return {
          loading: false,
          user: action.payload,
          error: '',
          success: action.success
        }
      case DELETE_ARTSTYLE_FAILURE:
        return {
          loading: false,
          user: [],
          error: action.payload
        }
        case UPDATE_ARTSTYLE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case UPDATE_ARTSTYLE_SUCCESS:
        return {
          loading: false,
          user: action.payload,
          error: '',
          success: action.success
        }
      case UPDATE_ARTSTYLE_FAILURE:
        return {
          loading: false,
          user: [],
          error: action.payload
        }
      case CLEAR_STORE:               
        return {
        loading: false,
        artist: undefined,
        error: ''       
      } 
    default: return state
  }
}

export default reducer
