import {
    FETCH_ARTWORK_REQUEST,
    FETCH_ARTWORK_SUCCESS,
    FETCH_ARTWORK_FAILURE,
    POST_ARTWORK_REQUEST,
    POST_ARTWORK_SUCCESS,
    POST_ARTWORK_FAILURE,
    UPDATE_ARTWORK_REQUEST,
    UPDATE_ARTWORK_SUCCESS,
    UPDATE_ARTWORK_FAILURE,
    DELETE_ARTWORK_REQUEST,
    DELETE_ARTWORK_SUCCESS,
    DELETE_ARTWORK_FAILURE,
    USERID_ARTWORK_REQUEST,
    USERID_ARTWORK_SUCCESS,
    USERID_ARTWORK_FAILURE,
    CLEAR_STORE,
    SET_SELECTED_ART_WORK
  } from './type'
  
  const initialState = {
    loading: false,
    selectedArtWork: [],
    artistArtWork: [],
    error: ''
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ARTWORK_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_ARTWORK_SUCCESS:
        return {
          ...state,
          loading: false,
          artistArtWork: action.payload,
          error: ''
        }
      case FETCH_ARTWORK_FAILURE:
        return {
          loading: false,
          selectedArtWork: [],
          error: action.payload
        }
        case USERID_ARTWORK_REQUEST:
        return {
          ...state,
          loading: true
        }
      case USERID_ARTWORK_SUCCESS:
        return {
          loading: false,
          selectedArtWork: action.payload,
          error: ''
        }
      case USERID_ARTWORK_FAILURE:
        return {
          loading: false,
          artist: [],
          error: action.payload
        }
      case POST_ARTWORK_REQUEST:
        return {
          ...state,
          loading: true
        }
      case POST_ARTWORK_SUCCESS:
        return {
          loading: false,
          selectedArtWork: action.payload,
          error: '',
          success: action.success
        }
      case POST_ARTWORK_FAILURE:
        return {
          loading: false,
          selectedArtWork: [],
          error: action.payload
        }
      case DELETE_ARTWORK_REQUEST:
        return {
          ...state,
          loading: true
        }
      case DELETE_ARTWORK_SUCCESS:
        return {
          loading: false,
          selectedArtWork: action.payload,
          error: '',
          success: action.success
        }
      case DELETE_ARTWORK_FAILURE:
        return {
          loading: false,
          selectedArtWork: [],
          error: action.payload
        }
        case UPDATE_ARTWORK_REQUEST:
        return {
          ...state,
          loading: true
        }
      case UPDATE_ARTWORK_SUCCESS:
        return {
          ...state,
          loading: false,
          selectedArtWork: action.payload,
          error: '',
          success: action.success
        }
      case UPDATE_ARTWORK_FAILURE:
        return {
          ...state,
          loading: false,
          selectedArtWork: [],
          error: action.payload
        }
      case CLEAR_STORE:               
        return {
          loading: false,
          artist: undefined,
          error: ''       
      } 
      case SET_SELECTED_ART_WORK:
        return {
          ...state,
          selectedArtWork: action.payload
        }
      default: return state
    }
  }
  
  export default reducer;
  