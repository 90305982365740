const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
    restricted: false,
    public: true
  },
  {
    path: ["/registration"],
    exact: false,
    component: "Registration",
    restricted: true,
    public: true
  },
  {
    path: ["/profile"],
    exact: false,
    component: "Profile",
    restricted: false,
    public: false
  },
  {
    path: ["/artist/:userName"],
    exact: false,
    component: "Profile",
    restricted: false,
    public: true
  },
  {
    path: ["/profile/edit"],
    exact: false,
    component: "Profile",
    restricted: false,
    public: false
  },
  {
    path: ["/submit-art-work"],
    exact: false,
    component: "SubmitArtWork",
    restricted: false,
    public: false
  }
 
  
];

export default routes;
